<template>
  <div class="p-1 rounded m-half best-deal-container" role="button"
    :class="subscribedPlan.planId == plan.id ? 'selected' : 'border'"
    :style="subscribedPlanId == plan.id ? 'opacity: 0.5; cursor: not-allowed' : ''"
    @click="setPlan(plan)"
>
    <div class="d-flex justify-content-between align-items-center mb-1">
      <h3>
        {{ plan.name }}
      </h3>
      <h2 class="ml-3">
        ${{ planFee }}
      </h2>
    </div>
    <div class="mb-1">
      {{ plan.description }}
      <p v-if="!singlePlan" :id="`planDetails-${plan.id}`" class="text-primary">
        More Information
      </p>
    </div>
    <div v-if="singlePlan" class="p-half mb-1">
      <ul>
        <li v-for="(item, index) in plan.details" :key="index">
          <div :class="item.is_highlight ? 'text-warning font-weight-bold' : ''">
            {{ item.feature }}
          </div>
        </li>
      </ul>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <span class="mx-1">Monthly Pay</span>
      <div class="toggle-switch" :class="{ active: !isMonthly }" @click="toggleSchedule">
        <div class="slider"></div>
      </div>
      <span class="mx-1">Yearly Pay</span>
    </div>
    <transition name="fade">
      <div v-if="!isMonthly">
        <div class="best-deal d-flex align-items-center mt-1">
          <img :src="require('@/assets/images/svg/best-price.svg')" class="img-fluid rounded text-white" width="48px">
          <div class="ml-1" :class="{'animate-bigger': !isMonthly}">
            <div>
              You can save  a lot
            </div>
            <h4 class="text-center">
              $ {{ plan.yearlyDiscount }} OFF
            </h4>
          </div>
        </div>
      </div>
    </transition>
    <dx-util-popover
      :width="420"
      :target="`#planDetails-${plan.id}`"
      show-event="dxhoverstart"
      hide-event="dxhoverend"
      position="left"
    >
      <div class="text-center p-half">
        <p-icon :name="plan.iconUrl" />
        <h3 class="my-half">
          {{ plan.name }}
        </h3>
        <div class="mt-half">
          <small>
            {{ plan.description }}
          </small>
          <div class="mt-1">
            <span>Main Benefits</span>
            <ul class="text-left mt-half">
              <li v-for="(item, index) in plan.details" :key="index">
                <div :class="item.is_highlight ? 'text-warning font-weight-bold' : ''">
                  {{ item.feature }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </dx-util-popover>
  </div>
</template>

<script>
import {
  ref, computed, watchEffect, // onMounted,
} from '@vue/composition-api'
import chargeScheduleEnum from '@/enums/membershipChargeSchedule.enum'
import { v4 as uuidv4 } from 'uuid'
// æimport useCurrentUser from '@/libs/app/current-user'
import { useCustomerRegistration } from '../useCustomerRegistration'

export default {
  components: {
  },
  props: {
    plan: {
      type: Object,
      reqiured: true,
      default: () => {},
    },
    subscribedPlanId: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isMonthly = ref(true)
    const planSchedule = ref(chargeScheduleEnum.MONTHLY.value)
    const planId = ref(null)
    const {
      setPlanId, setChargeSchedule, customerPlans, companyRegistrationForm, subscribedPlan,
    } = useCustomerRegistration()

    function setPlan(plan) {
      planId.value = plan.id
      setPlanId(planId.value)
      setChargeSchedule(planSchedule.value)
      emit('emit-plan-selected', { planId: planId.value, planSchedule: planSchedule.value })
    }
    const singlePlan = computed(() => customerPlans.value.length === 1)

    function toggleSchedule() {
      isMonthly.value = !isMonthly.value
      planSchedule.value = isMonthly.value ? chargeScheduleEnum.MONTHLY.value : chargeScheduleEnum.YEARLY.value
      setChargeSchedule(planSchedule.value)
      emit('emit-plan-selected', { planId: planId.value, planSchedule: planSchedule.value })
    }

    watchEffect(() => {
      if (singlePlan.value) {
        planId.value = customerPlans.value[0].id
        setPlanId(planId.value)
        setChargeSchedule(planSchedule.value)
      }
    })

    // const { subscribedMembershipPlans } = useCurrentUser()

    // onMounted(() => {
    //   subscribedPlan.planId = subscribedMembershipPlans[0]?.planId
    //  subscribedPlan.chargeSchedule = subscribedMembershipPlans[0]?.chargeSchedule
    // })

    const moreInfoComponentId = ref('')
    const selectedPlan = ref({})
    function openMoreInfo(plan) {
      moreInfoComponentId.value = uuidv4()
      selectedPlan.value = plan
    }

    const planFee = computed(() => parseInt((isMonthly.value ? props.plan.monthlyFee : (props.plan.monthlyFee * 12) - props.plan.yearlyDiscount), 10))

    return {
      isMonthly,
      planSchedule,
      planId,
      setPlanId,
      setChargeSchedule,
      planFee,
      toggleSchedule,
      setPlan,
      companyRegistrationForm,
      singlePlan,
      subscribedPlan,
      openMoreInfo,
      moreInfoComponentId,
      selectedPlan,
      // subscribedMembershipPlans,
    }
  },
}
</script>

<style lang="scss" scoped>
.toggle-switch {
  display: inline-block;
  width: 60px;
  height: 30px;
  background-color: #1079dc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  transition: left 0.3s ease-in-out;
}

.active .slider {
  left: 32px; /* Adjust the left value to match your desired position */
}

.active {
  background-color: #20f38d;
}

.best-deal-container {
  position: relative;
}

.best-deal {
  position: absolute;
  top: 50px;
  left: calc(30vw - 200px);
  padding: 8px;
  transform: rotate(-8deg);
  border-radius: 4px;
  background-color: rgb(26, 36, 77, 0.8);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
@keyframes animateBigger {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.animate-bigger {
  animation: animateBigger 1s ease-in-out;
}
.selected {
  border: 1px solid #6aaaee;
  box-shadow: 0 0 10px rgba(114, 114, 175, 0.5);
  transition: box-shadow 0.5s ease-in-out;
}

.selected:hover {
  box-shadow: 0 0 5px rgba(122, 122, 138, 0.5);
}
</style>
