/**
 * Enum for Membership Charge Schedules
 * @readonly
 * @enum {{key: string, value: string|null, text: string}}
 */

const membershipChargeScheduleEnum = Object.freeze({
  // DAILY: { key: 'DAILY', value: 'DAILY', text: 'DAILY' },
  // WEEKLY: { key: 'WEEKLY', value: 'WEEKLY', text: 'WEEKLY' },
  // BIWEEKLY: { key: 'BIWEEKLY', value: 'BIWEEKLY', text: 'BIWEEKLY' },
  MONTHLY: { key: 'MONTHLY', value: 'MONTHLY', text: 'MONTHLY' },
  YEARLY: { key: 'YEARLY', value: 'YEARLY', text: 'YEARLY' },
})

function getChargeScheduleList() {
  const options = []
  Object
    .keys(membershipChargeScheduleEnum)
    .map(key => options.push(
      {
        value: membershipChargeScheduleEnum[key].value,
        text: membershipChargeScheduleEnum[key].text,
      },
    ))
  return options
}

export default membershipChargeScheduleEnum

export {
  getChargeScheduleList,
}
